import React from 'react';
import SliderContent from 'src/components/mobile/sliderContent/sliderContent';
import SubSlide1 from './subSlide1';
import SubSlide2 from './subSlide2';
import SubSlide3 from './subSlide3';
import SubSlide4 from './subSlide4';
import SubSlide5 from './subSlide5';
import SubSlide6 from './subSlide6';

import { BlockContainer } from '../../../_templates/_styles';

const WrapperSubSlider = () => (
  <BlockContainer style={{ padding: '4rem 0' }}>
    <SliderContent>
      <SubSlide1 />
      <SubSlide2 />
      <SubSlide3 />
      <SubSlide4 />
      <SubSlide5 />
      <SubSlide6 />
    </SliderContent>
  </BlockContainer>
);

export default WrapperSubSlider;
