import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useInView } from 'react-intersection-observer';
import {
  PhotoWrapper, PhotoPerson, PhotoImg, Wrapper, WrapperText,
} from './styles';

const SignedPersonFull = ({
  photo,
  text,
  colorText = '#ffffff',
  alt,
  fullScreen = false,
  personName,
  containerStyle,
  noPhotoPadding,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  });
  return (
    <>
      <Wrapper ref={ref} style={containerStyle}>
        <PhotoWrapper visible={inView} noPhotoPadding={noPhotoPadding}>
          <PhotoImg fixed={photo} alt={alt} />
          <PhotoPerson>
            {personName}
          </PhotoPerson>
        </PhotoWrapper>
        <WrapperText
          colorText={colorText}
          visible={inView}
          fullScreen={fullScreen}
          noPhotoPadding={noPhotoPadding}
        >
          <MDXRenderer>{text}</MDXRenderer>
        </WrapperText>
      </Wrapper>
    </>
  );
};

export default SignedPersonFull;
