import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const textStyle = {
  textAlign: 'center',
  color: 'black !important',
  fontFamily: 'Red Hat Display',
  fontSize: '1.3rem !important',
  fontWeight: 700,
  lineHeight: 0.1,
};

const Slide = ({ query, subSlider, title }) => (
  <BlockContainer height="1580px" bg="rgba(0,0,0,0.5)">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ height: '6%', marginTop: '8rem', ...textStyle }} body={query.mdx.body} />
      {subSlider}
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
