import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import HoverDot from 'src/components/desktop/hoverDot/hoverDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import Caption from 'src/components/desktop/caption/caption';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer1: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-parallax1.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer2: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-parallax2.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  jozefHaller: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  tadeuszRozwadowski: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal2.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  jozefPilsudski: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal3.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  kazimierzSosnkowski: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal4.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  wincentyWitos: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal5.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  ignacyDaszynski: file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal6.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'horizontal' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId] && assetQuery[modalId].childImageSharp.fixed });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };
  return (
    <SlideLarge>
      <ParallaxMordo column>
        <Mordo
          mainscene
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
          move={{ x: -20, y: -10 }}
        />

        <Mordo
          scenelayer={{ fromBottom: '-15%' }}
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={1}
        />
        <Mordo
          scenelayer
          scene={1}
          move={{ x: -20, y: 10 }}
          background={{ image: assetQuery.scenelayer2.childImageSharp.fluid }}

          opacity={1}
        />
        <Mordo
          flexi={{ flex: '0 0 40%' }}
          scene={2}
          move={{ x: -5, y: -5 }}
        >
          <Caption body={query.caption.body} dark specialSize="1.25" />

        </Mordo>
        <Mordo
          scenelayer
          scene={3}
        >
          {query.hoverDot.exports.hoverInfo.map((dot, i) => <HoverDot key={dot.identifier} insideText={dot.insideText} positionLeft={dot.positionLeft} positionTop={dot.positionTop} clickDot={() => clickHandler(dot.identifier)} />)}
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
