import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    top: 70px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 400ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const CaptionContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;


  & > h2 {
    ${{ ...fonts.headline_1 }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    top: 60px;
    animation: ${animationTop};
  }

  & > h3, & > p, & > div > p {
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    margin-bottom: 2.43rem;
    animation: ${animationTopSecondary};
    font-family: 'Red Hat Display', 'Open Sans';
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 0.89px;
    ${({ specialSize }) => specialSize && `
      font-size: ${specialSize}rem;
    `}
  }
`;

export const Wrapper = styled.div`
width: 60rem;
max-zoom: 40%;
position: relative;
opacity: 0;
animation: ${animationBottom};
`;
