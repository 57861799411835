import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { CaptionContainer, Wrapper } from './styles';

const Caption = ({
  body, dark, style, specialSize
}) => {
  const [ref, inView] = useInView({
    threshold: 0.8,
  });
  return (
    <CaptionContainer dark={dark} ref={ref} visible={inView} style={style} specialSize={specialSize}>
      <MDXProvider components={{
        Wrapper,
        p: (props) => <p {...props} tabIndex="0" />,
      }}
      >
        <MDXRenderer visible={inView}>{body}</MDXRenderer>
      </MDXProvider>
    </CaptionContainer>
  );
};

export default Caption;
