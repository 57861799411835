import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/fr/chapter_4/sub_6/slide1';


import WrapperMobile from 'src/slides/mobile/fr/chapter_4/sub_6/wrapperMobile';
import { isMobile } from 'react-device-detect';
// import Slide
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Mobile = () => (
  <>
    <SEO title="Qui était l'auteur du plan de bataille ? | bataille de Varsovie" lang="fr" description="Les personnes les plus importantes qui ont soutenu le succès de la Pologne en 1920." />
    <WrapperMobile />
  </>
);

const Desktop = () => (
  <>
    <SEO title="Qui était l'auteur du plan de bataille ? | bataille de Varsovie" lang="fr" description="Les personnes les plus importantes qui ont soutenu le succès de la Pologne en 1920." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
